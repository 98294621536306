import Nav from 'components/Nav'
import Footer from 'components/Footer'
import 'styles/mainStyle.scss'
import 'styles/utilities.scss'
import Cart from '../components/Cart'
import CartContext from '../context/cart'
import { useEffect, useState, useRef } from 'react'

if (typeof window !== "undefined") {
	const $ = require('jquery')
	require('popper.js/dist/umd/popper')
	require('bootstrap')
}

export default ({ children }) => {
	// By using useState in combination with the context provider you can set the state of the context
	const [showCart, setShowCart] = useState(false)

	const firstRender = useRef(true)
	useEffect(() => {	
		if(firstRender.current) {
			firstRender.current = false
			return
		}

		if (typeof document !== 'undefined') {
			const body = Array.from(document.getElementsByTagName('body'))[0]
			showCart ? body.classList.add('disableScroll') : body.classList.remove('disableScroll')

			const cart = document.getElementById('cart')
			const classList = Array.from(cart.classList)
			if(showCart){
				if(classList.includes('slideOutOfView')) cart.classList.remove('slideOutOfView')
				cart.classList.add('slideIntoView')
			} else {
				if(classList.includes('slideIntoView')) cart.classList.remove('slideIntoView')
				cart.classList.add('slideOutOfView')
			}
		}
	}, [showCart])
	
	return (
		<CartContext.Provider value={{ showCart, setShowCart }}>
			<div onClick={() => { if (showCart) setShowCart(false) }} className={showCart ? 'focusCart' : ''}>
				<Nav />
				<main>{children}</main>
				<Footer />
			</div>
			<Cart />
		</CartContext.Provider>
	)
}
