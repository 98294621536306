module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K3SC9TR","enableWebVitalsTracking":true,"includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/images/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2c08fe9316766a13448b856e90a3e86a"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"mode":"async","preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Poppins","file":"https://fonts.googleapis.com/css2?family=Poppins&display=swap"},{"name":"Roboto","file":"https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"},{"name":"Zen Tokyo Zoo","file":"https://fonts.googleapis.com/css2?family=Zen+Tokyo+Zoo:wght@400&display=swap"},{"name":"Pathway Gothic One","file":"https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&display=swap"},{"name":"Montserrat","file":"https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap"},{"name":"Michroma","file":"https://fonts.googleapis.com/css2?family=Michroma&display=swap"},{"name":"Microgramma Extd D Bold","file":"https://use.typekit.net/mfv0aww.css"},{"name":"Shuttleblock Condensed","file":"https://use.typekit.net/rhp3skp.css"},{"name":"Changeling Neo","file":"https://use.typekit.net/wmi5bia.css"},{"name":"Inter","file":"https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"},{"name":"Oswald","file":"https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700&display=swap"},{"name":"Audiowide","file":"https://fonts.googleapis.com/css2?family=Audiowide&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.thempcstore.com/"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
