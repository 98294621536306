import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faShoppingCart, faSearch } from "@fortawesome/free-solid-svg-icons"
import useStickyNav from 'common/hooks/useStickyNav'
import { useContext } from "react"
import CartContext from '../context/cart'
import useFastSpringStore from 'common/modules/FastSpringStore/useFastSpringStore'
import '../styles/nav.scss'

export default () => {
	const { isSticky, navRef, toggleNav, navVisible } = useStickyNav()
	const { setShowCart } = useContext(CartContext)
	const [{ productsInCart }] = useFastSpringStore()

	return (
		<nav className={`navbar bg-black navbar-expand-xl btm-bdr sticky-top`} ref={navRef}>
			<Link className="navbar-brand m-0" to="/" style={{ maxWidth: '225px' }}>
				<StaticImage src="../images/full-logo.png" alt="logo" placeholder="akai profressional mpc software logo" className="mt-1" width={250} />
			</Link>

			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" onClick={toggleNav}>
				<StaticImage src="../images/toggler.svg" alt="toggler" placeholder="blurred" layout="fixed" width={30} height={30} />
			</button>

			<div className={`collapse navbar-collapse ${navVisible ? 'show' : ''}`} id="navbarNav">
				<ul className="navbar-nav w-100 align-items-center text-center">
					<li className="nav-item">
						<Link to="/mpc-expansions" className="nav-link" activeClassName="active">
							MPC Expansions
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/mpc-beats-editions" className="nav-link" activeClassName="active">
							MPC Beats Editions
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/mpc-software" className="nav-link" activeClassName="active">
							MPC Software
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/software-preset-packs" className="nav-link" activeClassName="active">
							Software Presets Packs
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/mpc-plugin-instruments/" className="nav-link" activeClassName="active">
							MPC/FORCE PLUG-INS
						</Link>
					</li>

					<div className="d-flex align-items-center mt-3 mt-xl-0 ml-xl-auto mx-auto mx-xl-0">
						<li className="nav-item text-center">
							<Link to="/search" className="search nav-link">
								<FontAwesomeIcon icon={faSearch} size="lg" title="Search" />
							</Link>
						</li>
						<li className="nav-item d-none d-md-block position-relative">
							{
								productsInCart.length > 0 &&
								<span className="cartCount position-absolute">{productsInCart.length <= 9 ? productsInCart.length : '9+'}</span>
							}
							<button onClick={() => setShowCart(true)} title="Cart" className='p-0 cartButton bg-transparent nav-link border-0'>
								<FontAwesomeIcon icon={faShoppingCart} color="white" size="lg" title="Shopping cart" />
							</button>
						</li>
						<li className="nav-item text-center">
							<a className="nav-link" href="https://akaipro.onfastspring.com/account" target="_blank">
								<FontAwesomeIcon icon={faUser} size="lg" title="Account" />
							</a>
						</li>
					</div>
				</ul>

			</div>
		</nav>
	)
}
