import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faTwitter, faTiktok, faFacebook, faYoutube, faSoundcloud } from '@fortawesome/free-brands-svg-icons'
import BrandsSlider from 'components/BrandsSlider'
import Year from '../../../common/src/components/year'

export default () => (
	<>
		<div id="bottom" className="container-fluid bg-black top-bdr">
			<div className="row justify-content-center bg-footer-img">
				<div className="col-xl-9 col-lg-10 col-md-12 p-0">
					<div className="row pt-5 pb-5 m-0">

						<Link to="/" className="col-lg-3 col-md-3 logo pr-4">
							<StaticImage
								imgClassName="img-fluid"
								src="../images/full-logo.png"
								alt="air logo"
								placeholder="none"
								height={35}
							/>
						</Link>


						<div className="col">
							<h5>PRODUCTS</h5>
							<ul className="text-left pl-0">
								<li><Link to="/mpc-expansions">MPC Expansions</Link></li>
								<li><Link to="/mpc-beats-editions">MPC Beats Editions</Link></li>
								<li><Link to="/mpc-software">MPC Software</Link></li>
								<li><Link to="/software-preset-packs">Software Preset Packs</Link></li>
								<li><Link to="/mpc-plugin-instruments">MPC/FORCE Plug-ins</Link></li>
							</ul>
						</div>


						<div className="col">
							<h5>SUPPORT</h5>
							<ul className="text-left pl-0">
								<li><a href="https://support.akaipro.com/" target="_blank">Knowledge Base</a></li>
								<li>
									<a href="https://support.akaipro.com/" target="_blank">Contact Support</a>
								</li>
								<li><Link to='/refundpolicy'>Refund Policy</Link></li>
								<li><Link to='/downloads'>Downloads</Link></li>
							</ul>
						</div>


						<div className="col">
							<h5>COMPANY</h5>
							<ul className="text-left pl-0">
								<li><Link to="/about-us.html">About Us</Link></li>
							</ul>
						</div>


						<div className="col-auto">
							<h5>FOLLOW US</h5>

							<ul className="social-list row pl-0">
								<li className="col-auto pl-3 p-2">
									<a href="https://www.instagram.com/akai_pro" target="_blank">
										<FontAwesomeIcon
											icon={faInstagram}
											style={{
												width: 'auto',
												height: '28px'
											}}
										/>
									</a>
								</li>
								<li className="col-auto p-2">
									<a href="https://www.facebook.com/AkaiPro/" target="_blank">
										<FontAwesomeIcon
											icon={faFacebook}
											style={{
												width: 'auto',
												height: '28px'
											}}
										/>
									</a>
								</li>
								<li className="col-auto p-2">
									<a href="http://www.youtube.com/user/akaiprovideo" target="_blank">
										<FontAwesomeIcon
											icon={faYoutube}
											style={{
												width: 'auto',
												height: '28px'
											}}
										/>
									</a>
								</li>
								<li className="col-auto p-2">
									<a href="https://www.soundcloud.com/akaipro" target="_blank">
										<FontAwesomeIcon
											icon={faSoundcloud}
											style={{
												width: 'auto',
												height: '28px'
											}}
										/>
									</a>
								</li>
								<li className="col-auto p-2">
									<a href="https://twitter.com/akai_pro" target="_blank">
										<FontAwesomeIcon
											icon={faTwitter}
											style={{
												width: 'auto',
												height: '28px'
											}}
										/>
									</a>
								</li>
								<li className="col-auto p-2">
									<a href="https://www.tiktok.com/@akai_pro" target="_blank">
										<FontAwesomeIcon
											icon={faTiktok}
											style={{
												width: 'auto',
												height: '28px'
											}}
										/>
									</a>
								</li>
							</ul>
						</div>
					</div>

				</div>
			</div>
		</div>
		<footer>
			<div className="container-fluid bglight">
				<div className="row justify-content-center align-items-center text-center">
					<div className="col-10 col-xl-8 p-0">

						<div id="inmusic-logos-scroller">
							<div className="inmusic-logos-scroller-title text-center">
								<StaticImage
									imgClassName="invert"
									src="../images/brand-logos/logo-inmusic-wht.png"
									placeholder="blurred"
									height={20}
									alt="InMusic logo"
								/>
								<p>Home of the world's premier music and audio technology brands</p>
								<hr />
							</div>
							<BrandsSlider />
						</div>
						<br />
						<div className="row text-center mb-5">
							<div className="col copyright">
								<p>© <Year /> inMusic Brands Inc. • All Rights Reserved.</p>
								<p>
									<a href="https://www.akaipro.com/privacy-policy" target="_blank">
										Privacy Policy
									</a>
									·
									<a href="https://www.akaipro.com/terms-of-use" target="_blank">
										Terms of Use
									</a>
								</p>
							</div>
						</div>

					</div>
				</div>
			</div>
		</footer>
	</>
)
